import { GatsbyImage } from "gatsby-plugin-image";
import React, { ReactNode } from "react";
import Background from "./background";

type ErrorScreenProps = {
  children: ReactNode;
  title: string;
};

const ErrorScreen = ({ children, title }: ErrorScreenProps): JSX.Element => {
  return (
    <>
      <div className="absolute left-0 top-0 w-full h-full">
        <Background theme="Blue" />
      </div>
      <div className="flex flex-col justify-center absolute left-0 top-0 w-full h-full px-4">
        <h1
          style={{
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
          className="relative max-w-3xl mx-auto sm:mt-8 lg:mt-0 bg-gradient-to-b from-white to-ice-headline-base font-display text-5xl sm:text-7xl md:text-8xl text-center text-white uppercase tracking-wider leading-display sm:leading-display md:leading-display"
        >
          {title}
        </h1>
        <div className="flex relative sm:max-w-lg md:max-w-xl mx-auto">
          <img
            src="/assets/404.png"
            className="hidden sm:block w-1/3 sm:-mt-4 md:-mt-6"
          />
          <div className="sm:w-2/3 mt-8 prose prose-invert sm:prose-lg md:prose-xl prose-p:text-white prose-a:text-white text-center font-serif">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorScreen;
