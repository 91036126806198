import { Link } from "gatsby";
import React, { ReactNode } from "react";
import ErrorScreen from "../components/error-screen";
import Layout from "../components/layout";
import SimpleNavbar from "../components/navbar/simple-navbar";

const renderBody = () => (
  <p>
    404 error. Looks like you wandered off somewhere that doesn't exist. Yet. Or
    anymore. Click <Link to="/">here</Link> to get back to our main page.
  </p>
);

const NotFoundPage = (): JSX.Element => {
  return (
    <Layout>
      <div className="relative w-full min-h-screen bg-black">
        <div className="relative z-10">
          <SimpleNavbar inverse />
        </div>
        <ErrorScreen title="Out of Ammo">{renderBody()}</ErrorScreen>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
