import React from "react";

export type BackgroundTheme = "Blue" | "Lilac";

type BackgroundProps = {
  theme: BackgroundTheme;
};

const Background = ({ theme }: BackgroundProps): JSX.Element => {
  const backroundUrl =
    theme === "Blue" ? "background-blue.svg" : "background-lilac.svg";
  const rotatorUrl =
    theme === "Blue" ? "rotator-blue.svg" : "rotator-lilac.svg";

  return (
    <>
      <div
        style={{ backgroundImage: `url(/assets/${backroundUrl})` }}
        className="absolute inset-0 bg-center bg-cover bg-no-repeat"
      />
      <div
        style={{ backgroundImage: `url(/assets/${rotatorUrl})` }}
        className="absolute inset-0 bg-center bg-cover bg-no-repeat"
      />
    </>
  );
};

export default Background;
